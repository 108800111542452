.container {
  position: relative;

  & svg {
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 16px;
    width: 16px;
  }

  & input:focus + svg {
    @apply text-brand-100;
  }

  &::before {
  }
}

.filter::after {
  content: url('/svg/icons/filter.svg');
}

.animatedLabel {
  top: 14px;
  left: 16px;
  transform: scale(1) translate(0, 0);
  transform-origin: top left;
  transition: transform 0.2s ease-in-out;

  &.focus {
    @apply text-brand-100;
    transform: scale(0.7) translate(0, -18px);
  }
}

.inputField {
  -webkit-box-shadow: 0 0 0px 1000px white inset;
}
