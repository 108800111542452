.collaspible {
  transition: height 0.3s ease-in-out;

  &:last-child {
    border: none;
  }

  p:not(p:first-of-type) {
    @apply pt-3;
  }

  p:first-of-type() {
    padding-top: 0;
  }

  a {
    position: relative;

    &::before {
      position: absolute;
      content: '';
      height: 1px;
      width: 100%;
      bottom: 0;
      left: 0;
    }

    &::before {
      @apply bg-neutral-30;
    }

    &::after {
      @apply bg-brand-100;
      position: absolute;
      content: '';
      height: 1px;
      width: 0;
      bottom: 0;
      left: 0;
      transition: width 0.3s ease-in-out;
    }

    &:hover {
      &::after {
        width: 100%;
      }
    }
  }
}

.collaspibleItem {
  grid-template-columns: 1fr 16px;
}

.icon {
  transition: transform 0.3s ease-in-out;

  &.active {
    transform: rotate(180deg);
  }
}
