.list {
  @apply md:grid;

  @media (min-width: 1024px) {
    grid-template-columns: repeat(4, 1fr);
  }
}

.columnSpan {
  @apply col-span-4;
}

.splitInColumns {
  @apply mt-9;

  &:first-child {
    @apply mt-6;
  }
}

@media (max-width: 1024px) {
  .splitInColumns {
    @apply mt-6;
  }
}
