.list {
  counter-reset: items;
}

.item:last-child {
  margin-bottom: 0;
}

.item::before {
  @apply text-white;
  counter-increment: items;
  content: counter(items);
  display: block;
  position: absolute;
  height: 3rem;
  width: 3rem;
  font-size: 1.25rem;
  line-height: 3rem;
  font-weight: bold;
  text-align: center;
  z-index: 1;
}

.itemShape {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  height: 3rem;
  width: 3rem;
  margin-right: 1.5rem;
}
