.vehiclePreviewGrid {
  grid-template-columns: auto 1fr;
}

.withEdit {
  grid-template-columns: 1fr auto;
}

.overflowText {
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
