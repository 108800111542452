.card {
  --card-width: calc(100% / 5);
  width: calc(var(--card-width) - 24px);
}

@media screen and (max-width: 1023px) {
  .card {
    --card-width: 50%;
    width: calc(var(--card-width) - 12px);
  }
}
