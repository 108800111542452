.quote::before {
  @apply text-neutral-50;
  content: '"';
  font-size: 2rem;
  font-weight: 800;
  display: block;
  padding-right: 0.5rem;
  margin-top: -0.5rem;
}

.header {
  margin-top: -8px;

  @media (min-width: 1024px) {
    margin-top: 0px;
  }
}

.item {
  @media (min-width: 1024px) {
    border: none;
  }
}

.item:last-child {
  border: none;

  @media (min-width: 1024px) {
    border: none;
  }
}

.a {
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr;
}
