.collaspible {
  transition: height 0.3s ease-in-out;

  &:last-child {
    border: none;
  }
}

.icon {
  transition: transform 0.3s ease-in-out;

  &.active {
    transform: rotate(180deg);
  }
}
